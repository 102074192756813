<mat-form-field
  appearance="outline"
  class="white-bg label-field w-100"
  [class]="className"
  [ngClass]="{
    invalid: ctr && ctr.invalid,
    'ng-touched': ctr && ctr.touched,
    disabled: ctr && ctr.disabled
  }"
  [floatLabel]="!floatingLabel ? 'always' : 'auto'">
  <mat-label *ngIf="floatingLabel">{{ name }}</mat-label>
  <input
    matInput
    appOnlyNumber
    [class.text-right]="currency"
    [isNumberOnly]="numberOnly || currency"
    [isCurrency]="currency"
    [(ngModel)]="currentValue"
    (ngModelChange)="onValueChange()"
    [placeholder]="placeholder"
    [readonly]="readOnly"
    [disabled]="disabled"
    [required]="required()"
    [isRef]="isRef"
  />
</mat-form-field>

<div class="mat-error">
  <span *ngFor="let validator of validators[ctrName]">
    {{ validator.message }}
  </span>
</div>
